<template>
  <div class="box">
    <div class="information-haeder-box">
      <div class="box-top">
        <div class="box-top-left">
          <div @click="handelLishi" class="box-top-left-img">
            <img src="../../assets/img/views/jiantou.png" alt />
          </div>
          <div class="topicName">话题分析：{{ topicName }}</div>
        </div>
        <div class="box-top-right">
          <!-- <div class="box-top-right-btn3" v-if="topicType != 2" @click="handleCommand">
            单条报告
          </div> -->
          <!-- <div class="box-top-right-btn3" :class="{ noClick: GraphText }" v-if="topicType != 2" @click="SpecialReport">
            {{ GraphText ? "正在生成突发专报..." : "突发专报导出" }}
          </div> -->
          <div class="box-top-right-btn3" v-if="topicType != 2" @click="fenxiJump">
            舆情分析
          </div>
          <div class="box-top-right-btn3" v-if="topicType != 2" @click="zaixianJump">
            舆情再现
          </div>
          <div class="box-top-right-btn1" @click="
            clientDeleteMsg({
              topicId: topicData.id,
              organId: $store.state.user.organizationId,
            })
            " v-if="publicType != 1 && topicData.childParentType == 1">
            删除
          </div>
          <div class="box-top-right-btn1" @click="deletes" v-else>删除</div>
          <div @click="
            share({ topicId: topicData.id, organId: $store.state.user.organizationId })
            " v-if="isUpdate != 1 && publicType != 1 && topicData.childParentType == 1
            " class="box-top-right-btn4">
            深度更新
          </div>
          <div @click="updata" v-if="isUpdate != 1 && publicType != 1 && topicData.childParentType != 1
          " class="box-top-right-btn4">
            深度更新
          </div>
          <div @click="
            clientDeleteMsg({
              topicId: topicData.id,
              organId: $store.state.user.organizationId,
              isDeepUpdate: 1,
            })
            " v-if="isUpdate == 1 && publicType != 1 && topicData.childParentType == 1
            " class="box-top-right-btn2">
            关闭
          </div>
          <div @click="closeing" v-if="isUpdate == 1 && publicType != 1 && topicData.childParentType != 1
          " class="box-top-right-btn2">
            关闭
          </div>
          <div @click="exportReport" :class="['box-top-right-btn3', textWord ? 'disabled' : '']" v-if="topicType != 2">
            {{ textWord ? "生成报告中..." : "生成报告" }}
          </div>
          <div @click="exportReport2" :class="['box-top-right-btn3', textWord ? 'disabled' : '']" v-else>
            {{ textWord ? "生成报告中..." : "生成报告" }}
          </div>
        </div>
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="  " class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/topics/index' }">话题分析</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type == 'history'" :to="{ path: '/topics/index/topicList' }">
        <span>/</span>历史话题分析
      </el-breadcrumb-item>
      <el-breadcrumb-item class="breadcrumb-item">
        <span class="breadcrumb-meaasge">/</span>详情
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="box-content">
      <div class="box-content-top">
        <div style="display: flex">
          <div v-for="(item, inx) in tag" :key="inx" @click="jump(item)"
            v-show="!(topicType == 2 && item.name == '传播分析')" :class="name == item.value ? 'Selectli' : 'NoSelect'">
            {{ item.name }}
          </div>
        </div>
      </div>
      <component ref="dataAnalysis" :topicId="topicId" :topicType="topicType" :publicType="publicType" :id="id"
        :is="name" :topicData="topicData"></component>
    </div>
    <!-- 信息数据 -->
    <ul v-if="infoListData && infoListData.length != 0" class="box1-div2-center-left-table" id="top-table">
      <li v-for="(item, index) in infoListData" :key="index" v-show="item.totalComments">
        <div class="box1-div2-center-left-table-left">{{ item.showname }}</div>
        <div class="box1-div2-center-left-table-right">
          {{ item.totalComments }}（
          <img src="../../assets/img/views/monitoringScheme/shangsheng.png" alt />
          <span>{{ item.todayComments }}</span>）
        </div>
      </li>
    </ul>
    <!-- 评论数据 -->
    <ul v-if="countList.commentList && countList.commentList.length != 0" class="box1-div2-center-left-table"
      id="bottom-table">
      <li v-for="(item, index) in countList.commentList" :key="index">
        <div class="box1-div2-center-left-table-left">
          {{ item.showname ? item.showname : "无" }}
        </div>
        <div class="box1-div2-center-left-table-right">
          {{ item.totalComments }}（
          <img src="../../assets/img/views/monitoringScheme/shangsheng.png" alt />
          <span>{{ item.todayComments }}</span>
          ）
        </div>
      </li>
    </ul>
    <!-- 评论曲线 -->
    <div v-if="tabledata && tabledata.length != 0" class="box1-div4-center-left" id="showings2">
      <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#F5F6FA', color: '#333333' }"
        ref="multipleTable" :data="tabledata" tooltip-effect="dark" style="width: 100%; border: 1px solid #e4e6ec"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column label="排名" align="center" type="index" width="60"></el-table-column>
        <el-table-column prop="mainkeyword" label="关键词" width="120" align="center"></el-table-column>
        <el-table-column prop="amount" label="数量" align="center"></el-table-column>
      </el-table>
    </div>
    <!-- 媒体观点分析 -->
    <div v-if="dataList && dataList.length != 0" style="display: flex">
      <div id="showings3" class="box2-div2-center-left">
        <ul>
          <li style="padding-left: 0">
            <div class="mediaView-div1"></div>
            <div class="mediaView-div2">数据量</div>
            <div class="mediaView-div3">数据占比</div>
          </li>
          <li class="box2-div2-center-left-box" v-for="(item, inx) in dataList" :key="inx">
            <div class="box2-div2-center-left-text mediaView-div4">
              <el-tooltip class="item" effect="dark" :content="item.viewName" placement="top">
                <el-radio-group @change="changeRadio" v-model="radio">
                  <el-radio :label="item">{{ item.viewName }}</el-radio>
                </el-radio-group>
              </el-tooltip>
            </div>
            <div class="mediaView-div5">{{ item.useCount }}</div>
            <div v-if="item.count != 0" class="mediaView-div6">
              {{
                item.useCount
                  ? ((item.useCount / Number(item.count)) * 100).toFixed(2) +
                  "%"
                  : "无"
              }}
            </div>
            <div v-else class="mediaView-div6">无</div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 网民观点分析 -->
    <div class="box2-div2" v-if="topicType == 1">
      <div class="box2-div2-top" style="justify-content: space-between">
        <div class="img-box">
          <img src="../../assets/img/views/monitoringScheme/quanquan.png" alt />
          网民观点分析
        </div>

        <el-select style="width: 280px" v-model="sampling" placeholder="请选择" value-key="sampleId"
          @change="optionsChange">
          <el-option v-for="item in options" :key="item.sampleId" :label="item.createTime + ' ' + item.remark + '(' + item.sampleName + ')'
            " :value="item"></el-option>
        </el-select>
      </div>
      <div class="box2-div2-center" v-if="viewPointList && viewPointList.length != 0">
        <div id="showings4" class="box2-div2-center-left" style="
            width: 496px;
            padding: 0;
            margin-bottom: 25px;
            margin-left: 44px;
          ">
          <div class="viewPoint-left-text">
            <span class="viewPoint_text" style="float: left">{{
              samplingName
            }}</span>
            <span class="viewPoint_text" style="float: right">样本总数：{{ samplingCount }}</span>
          </div>
          <ul style="width: 100%">
            <li>
              <div class="li-left" style="width: 294px"></div>
              <div class="li-center" style="width: 120px">数据量</div>
              <div class="li-centerNum">数据占比</div>
            </li>
            <li v-for="(val, inx) in viewPointList" :key="inx">
              <div class="li-left1" style="width: 294px; text-align: left; padding-left: 18px">
                <el-tooltip class="item" effect="dark" :content="val.viewName" placement="top">
                  <el-radio @change="radioChange" class="redioText" v-model="radio2" :label="val">{{ val.viewName }}
                  </el-radio>
                </el-tooltip>
              </div>
              <div class="li-center1">{{ val.totalNum }}</div>
              <div class="li-center2">
                {{
                  val.viewMatch
                    ? (val.viewMatch / val.totalNum) * 100 + "%"
                    : "0%"
                }}
              </div>
            </li>
          </ul>
        </div>
        <div id="showing5" v-if="commitList && commitList.length != 0" class="viewPoint-right">
          <div class="viewPoint-right-font">
            突出样本 ({{ commitList.length }})
          </div>
          <div class="viewPoint-right-content">
            <div v-loading="loadingContent">
              <div class="viewPoint-div" v-for="item in commitList" :key="item.commentId">
                <div class="viewPoint-top">
                  <div class="viewPoint-tag">{{ item.mediaLink }}</div>
                  <span class="viewPoint-top-text">@{{ item.nickname }}:</span>
                  <span class="viewPoint-top-font">{{ item.content }}</span>
                </div>
                <div class="viewPoint-right-center">
                  <span class="viewPoint-right-content-text">{{ item.source }} |:</span>
                  <span class="viewPoint-right-content-font">
                    <a :href="item.website" target="_blank">{{ item.title }}</a>
                  </span>
                </div>
                <div class="viewPoint-right-bottom">
                  <span class="viewPoint-right-bottom-text">网民观点: {{ item.viewName }}</span>
                  <span class="viewPoint-right-bottom-font">{{
                    item.commentTime
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="analyse-container" v-else style="height: 243px; padding-bottom: 30px">
          <div class="analyse-container-img">
            <img src="../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>
    <deleteDialog ref="openDialog" @deleteFn="deleteFn" />
    <div class="options"></div>
    <div class="eventLine"></div>
    <div class="mapData"></div>
    <div id="infoMapData"></div>
    <!-- 下载提示 -->
    <el-dialog title="下载提示" :visible.sync="download.dialogFormVisible" width="700px" class="dialog-box"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :show-close="false">
      <div class="dialog-body">
        <span class="download-Wait" v-if="download.wite">正在下载...</span>
        <span class="download-Wait" v-else>下载完成！</span>
        <el-progress :stroke-width="22" :text-inside="true" :percentage="download.percentage"></el-progress>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downloadYes" size="medium" type="primary" class="search_z">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import map from "../../assets/js/China1.json";

import * as echarts from "echarts";
import generalization from "../topics/generalization";
import analysis from "./analysis";
import information from "./information";
import deleteDialog from "../../components/deleteDialog";
import {
  getInfo,
  deletes,
  editTopic,
  topicList,
  ascription,
  clientDeleteMsg,
  share,
  specialData,
  topicAIReportNewV4
} from "../../api/topic/topic";
export default {
  data() {
    return {
      topicData: {},
      infoListData: [],
      type: this.$route.query.type,
      name: sessionStorage.getItem("eventName")
        ? sessionStorage.getItem("eventName")
        : "generalization",
      topicType: this.$route.query.topicType, //话题类型
      topicId: Number(this.$route.query.topicId),
      id: this.$route.query.id,
      isDeepUpdate: "",
      isPause: "",
      topicName: "",
      topid: null,
      isUpdate: "",
      isDel: null,
      //专项报告
      GraphLine: "",
      GraphPie: "",
      wordCloud: "",
      GraphText: false,
      download: {
        dialogFormVisible: false,
        percentage: 0,
        wite: true,
        timeIndex: 0,
      },
      tag: [
        {
          name: "事件概况",
          value: "generalization",
        },
        {
          name: "传播分析",
          value: "analysis",
        },
        {
          name: "全部信息",
          value: "information",
        },
      ],
      inforListToday: [],
      inforListTotal: [],
      commentListToday: [],
      commentListTotal: [],
      whole: [],
      tabledata: [],
      // 评论曲线选中的数据
      multipleSelection:
        this.$store.state.multipleSelection.length != 0
          ? this.$store.state.multipleSelection.length != 0
          : [],
      eventLineList: [], //事件进展曲线
      eventLineTime: [], //事件进展曲线时间
      eventLineData: [], //事件进展曲线数据
      dataList: [],
      radio: {},
      // 网民观点分析列表
      viewPointList: [],
      options: [], //抽样下拉框
      //  抽样选择绑定
      sampling: "",
      //   抽样名字
      samplingName: "",
      //   抽样总数
      samplingCount: 0,
      //   抽样id
      samplingiD: "",
      //   评论列表
      commitList: [],
      // 当前网民观点的样本数量
      pageSize: 0,
      loadingContent: false,
      // 数据导出文字显示
      // textExport: false,
      // 生成报告文字显示
      textWord: false,
      key: [], //图例名字
      time: [], //存放时间
      arrData: [], //存放评论曲线数据
      GetMapData: [],
      dataEchart: [],
      // 存放信息数量he评论数量
      countList: {},
      radio2: {},
      publicType: null, //是否公共话题
      mapUrl: null,
    };
  },
  components: {
    deleteDialog,
    generalization,
    analysis,
    information,
  },
  created() {
    if (this.$route.query.moduleTap) {
      this.name = this.$route.query.moduleTap;
    }
    this.getInfo();
  },

  methods: {
    //分享话题深度更新
    async share(data) {
      let res = await share(data);
      if (res.data.code == 200) {
        this.$message.success("深度更新成功!");
        this.getInfo();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    //分享的话题脱离关联关系
    async clientDeleteMsg(data) {
      let res = await clientDeleteMsg(data);
      if (res.data.code == 200) {
        this.getInfo();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    playback() {
      window.open(
        `http://dp.new.sjyq.yuqing.pro/ZaiXian/index.html?Token=${sessionStorage.getItem(
          "token"
        )}&TopicID=${this.id}`
      );
    },
    // 智能报告
    async handleCommand() {
      this.download.dialogFormVisible = true;
      this.download.wite = true;
      this.downloadProgress();
      let res = await topicAIReportNewV4({
        "id": this.id,
        "userId": this.$store.state.user.userId,
        "operator": this.$store.state.user.name
      });
      if (res.data.code) {
        this.$message({
          message: res.data.msg,
          type: 'warning'
        });
        this.downloadYes();
      } else {
        clearTimeout(this.download.timeIndex);
        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = this.topicName + '-智能报告导出.docx'; // 设置下载文件名，可根据实际情况修改
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        clearTimeout(this.download.timeIndex);
        this.download.wite = false;
        this.download.percentage = 100;
      }
    },
    //下载进度模拟
    downloadProgress() {
      this.download.timeIndex = setTimeout(() => {
        if (this.download.percentage + 2 >= 90) {
          this.download.percentage = 90;
        } else {
          this.download.percentage += 2;
        }
        this.downloadProgress();
      }, 1000);
    },
    downloadYes() {
      this.download.dialogFormVisible = false;
      this.download.percentage = 0;
      this.download.wite = true;
      clearTimeout(this.download.timeIndex);
    },
    // 境内生成报告
    exportReport() {
      this.$message.success("正在生成报告！");
      this.textWord = true;
      this.$store.state.dataListTopic.topicId = this.id;
      this.$store.state.dataListTopic.organizationId =
        this.$store.state.user.organizationId;
      Promise.all([
        this.getInfoAmountCount(),
        this.eventLine(),
        this.commentLine(),
        this.topicSampleList(),
        this.mediaHot(),
        this.createdInfoMap(),
        this.viewPoint(),
      ]).then(() => {
        if (this.dataList.length != 0) {
          if (!this.$store.state.dataListTopic.viewAnalysis) {
            html2canvas(document.querySelector("#showings3")).then(
              (resolve) => {
                let imgUrl = resolve.toDataURL("image/png");
                this.$store.state.dataListTopic.viewAnalysis = imgUrl;
              }
            );
          }
        }
        if (this.countList.infoList && this.countList.infoList.length > 0) {
          if (this.inforListTotal.length != 0) {
            this.echarts1();
          }
          if (this.inforListToday.length != 0) {
            this.echartstwo();
          }
        }
        html2canvas(document.querySelector("#top-table")).then((resolve) => {
          let imgUrl = resolve.toDataURL("image/png"); //此时就得到了dom元素转成了base64的图片
          this.$store.state.dataListTopic.platformData = imgUrl;
        });
        if (this.topicType == 1) {
          if (
            this.countList.commentList &&
            this.countList.commentList.length != 0
          ) {
            if (this.commentListToday.length != 0) {
              this.echarts2two();
            }
            if (this.commentListTotal.length != 0) {
              this.echarts2();
            }
            html2canvas(document.querySelector("#bottom-table")).then(
              (resolve) => {
                let imgUrl = resolve.toDataURL("image/png"); //此时就得到了dom元素转成了base64的图片
                this.$store.state.dataListTopic.publishedData = imgUrl;
              }
            );
          }
        }
        if (this.topicType == 0) {
          this.$store.state.dataListTopic.regionRelease = this.mapUrl;
        }
        if (this.eventLineTime.length > 0) {
          this.echarts3();
        }
        if (this.tabledata && this.tabledata.length != 0) {
          if (!this.$store.state.dataListTopic.hotWords) {
            html2canvas(document.querySelector("#showings2")).then(
              (resolve) => {
                let imgUrl = resolve.toDataURL("image/png"); //此时就得到了dom元素转成了base64的图片
                this.$store.state.dataListTopic.hotWords = imgUrl;
              }
            );
          }
        }
        if (this.topicType == 1) {
          if (this.tabledata && this.tabledata.length > 0) {
            this.echarts4(this.key, this.time, this.arrData);
          }
        }
        if (this.dataEchart.length != 0) {
          this.echarts6(this.dataEchart);
        }
        setTimeout(() => {
          this.exportReporting();
        }, 5000);
      });
    },
    // 境外生成报告
    exportReport2() {
      this.$message.success("正在生成报告！");
      this.textWord = true;
      this.$store.state.dataListTopic.topicId = this.id;
      this.$store.state.dataListTopic.organizationId =
        this.$store.state.user.organizationId;
      Promise.all([this.getInfoAmountCount()]).then(() => {
        if (this.countList.infoList && this.countList.infoList.length > 0) {
          if (this.inforListTotal.length != 0) {
            this.echarts1();
          }
          if (this.inforListToday.length != 0) {
            this.echartstwo();
          }
        }
        html2canvas(document.querySelector("#top-table")).then((resolve) => {
          let imgUrl = resolve.toDataURL("image/png"); //此时就得到了dom元素转成了base64的图片
          this.$store.state.dataListTopic.platformData = imgUrl;
        });
        setTimeout(() => {
          this.exportReporting2();
        }, 5000);
      });
    },
    exportReporting() {
      this.textWord = false;
      let obj = {
        url: "api/topicnew/word/data",
        method: "post",
        data: this.$store.state.dataListTopic,
        responseType: "blob",
      };
      let textName = `${this.topicName}报告-${this.$store.state.user.name
        }-${this.$calcu.timeCycle(new Date(), "HH：mm")}.docx`;
      this.$parent.downloadFun(obj, textName);
    },
    exportReporting2() {
      this.textWord = false;
      let obj = {
        url: "api/topic/word/dataOver",
        method: "post",
        data: this.$store.state.dataListTopic,
        responseType: "blob",
      };
      let textName = `${this.topicName}报告-${this.$store.state.user.name
        }-${this.$calcu.timeCycle(new Date(), "HH：mm")}.docx`;
      this.$parent.downloadFun(obj, textName);
    },
    // 信息数量及评论数量
    getInfoAmountCount() {
      return new Promise((resolve) => {
        let inforListTodayObj = {};
        let inforListTotalObj = {};
        let commentListTodayObj = {};
        let commentListTotalObj = {};
        let dataLists = [];
        this.inforListToday = [];
        this.inforListTotal = [];
        this.commentListToday = [];
        this.commentListTotal = [];
        let url = "";
        if (this.topicType == 2) {
          url = "api/topic/overseas/getOverseasType";
          dataLists = [
            { showname: "新闻网站", totalComments: 0, todayComments: 0 },
            { showname: "社区论坛", totalComments: 0, todayComments: 0 },
            { showname: "社交媒体", totalComments: 0, todayComments: 0 },
            { showname: "机构官网", totalComments: 0, todayComments: 0 },
            { showname: "资讯平台", totalComments: 0, todayComments: 0 },
            { showname: "其他", totalComments: 0, todayComments: 0 },
          ];
          this.$http({
            method: "get",
            url: url,
            params: {
              topicId: this.id,
            },
          }).then((res) => {
            if (res.data.data) {
              // 接口返回的评论数据和信息数据
              this.countList = res.data.data;
              if (res.data.data.infoList) {
                dataLists.forEach((item) => {
                  res.data.data.infoList.forEach((it) => {
                    if (item.showname == it.showname) {
                      item.totalComments = it.totalComments;
                      item.todayComments = it.todayComments;
                    }
                  });
                });
                this.infoListData = dataLists;
              }
              this.infoListData.forEach((item) => {
                if (item.totalComments != 0) {
                  inforListTotalObj = {
                    name: item.showname,
                    value: item.totalComments,
                  };
                  this.inforListTotal.push(inforListTotalObj);
                }
                if (item.todayComments != 0) {
                  inforListTodayObj = {
                    name: item.showname,
                    value: item.todayComments,
                  };
                  this.inforListToday.push(inforListTodayObj);
                }
              });
              let flagone = false;
              let flagtwo = false;
              this.inforListToday.forEach((item) => {
                if (item.value != 0) {
                  flagone = true;
                }
              });
              this.inforListTotal.forEach((item) => {
                if (item.value != 0) {
                  flagtwo = true;
                }
              });
              if (!flagone) {
                this.inforListToday = [];
              }
              if (!flagtwo) {
                this.inforListTotal = [];
              }
              // 如果是境内+跟评topicType == 1 否则是topicType == 0
              if (this.topicType == 1) {
                if (this.countList.commentList) {
                  if (this.countList.commentList.length != 0) {
                    this.countList.commentList.forEach((item) => {
                      commentListTotalObj = {
                        name: item.showname,
                        value: item.totalComments,
                      };
                      commentListTodayObj = {
                        name: item.showname,
                        value: item.todayComments,
                      };
                      this.commentListToday.push(commentListTodayObj);
                      this.commentListTotal.push(commentListTotalObj);
                    });
                    let flagone = false;
                    let flagtwo = false;
                    this.commentListToday.forEach((item) => {
                      if (item.value != 0) {
                        flagone = true;
                      }
                    });
                    this.commentListTotal.forEach((item) => {
                      if (item.value != 0) {
                        flagtwo = true;
                      }
                    });
                    if (!flagone) {
                      this.commentListToday = [];
                    }
                    if (!flagtwo) {
                      this.commentListTotal = [];
                    }
                  }
                }
              }
            }
            resolve();
          });
        } else {
          url = "api/topic/viewPoint/getInfoAmountCount";
          dataLists = [
            {
              showname: "电视",
              totalComments: 0,
              todayComments: 0,
            },
            {
              showname: "纸媒",
              totalComments: 0,
              todayComments: 0,
            },
            {
              showname: "网站",
              totalComments: 0,
              todayComments: 0,
            },
            {
              showname: "微博",
              totalComments: 0,
              todayComments: 0,
            },
            {
              showname: "微信公众号",
              totalComments: 0,
              todayComments: 0,
            },
            {
              showname: "移动客户端",
              totalComments: 0,
              todayComments: 0,
            },
            {
              showname: "互动栏目",
              totalComments: 0,
              todayComments: 0,
            },
            {
              showname: "视频",
              totalComments: 0,
              todayComments: 0,
            },
          ];
          this.$http({
            method: "post",
            url: url,
            data: {
              topicId: this.id,
              organId: this.$store.state.user.organizationId,
            },
          }).then((res) => {
            if (res.data.data) {
              // 接口返回的评论数据和信息数据
              this.countList = res.data.data;
              if (res.data.data.infoList) {
                dataLists.forEach((item) => {
                  res.data.data.infoList.forEach((it) => {
                    if (item.showname == it.showname) {
                      item.totalComments = it.totalComments;
                      item.todayComments = it.todayComments;
                    }
                  });
                });
                this.infoListData = dataLists;
              }
              this.infoListData.forEach((item) => {
                if (item.totalComments != 0) {
                  inforListTotalObj = {
                    name: item.showname,
                    value: item.totalComments,
                  };
                  this.inforListTotal.push(inforListTotalObj);
                }
                if (item.todayComments != 0) {
                  inforListTodayObj = {
                    name: item.showname,
                    value: item.todayComments,
                  };
                  this.inforListToday.push(inforListTodayObj);
                }
              });
              let flagone = false;
              let flagtwo = false;
              this.inforListToday.forEach((item) => {
                if (item.value != 0) {
                  flagone = true;
                }
              });
              this.inforListTotal.forEach((item) => {
                if (item.value != 0) {
                  flagtwo = true;
                }
              });
              if (!flagone) {
                this.inforListToday = [];
              }
              if (!flagtwo) {
                this.inforListTotal = [];
              }
              // 如果是境内+跟评topicType == 1 否则是topicType == 0
              if (this.topicType == 1) {
                if (this.countList.commentList) {
                  if (this.countList.commentList.length != 0) {
                    this.countList.commentList.forEach((item) => {
                      commentListTotalObj = {
                        name: item.showname,
                        value: item.totalComments,
                      };
                      commentListTodayObj = {
                        name: item.showname,
                        value: item.todayComments,
                      };
                      this.commentListToday.push(commentListTodayObj);
                      this.commentListTotal.push(commentListTotalObj);
                    });
                    let flagone = false;
                    let flagtwo = false;
                    this.commentListToday.forEach((item) => {
                      if (item.value != 0) {
                        flagone = true;
                      }
                    });
                    this.commentListTotal.forEach((item) => {
                      if (item.value != 0) {
                        flagtwo = true;
                      }
                    });
                    if (!flagone) {
                      this.commentListToday = [];
                    }
                    if (!flagtwo) {
                      this.commentListTotal = [];
                    }
                  }
                }
              }
            }
            resolve();
          });
        }
      });
    },
    // 事件进展曲线
    eventLine() {
      return new Promise((resolve) => {
        // 图例数组
        this.eventLineNames = [];
        // x时间数组
        this.eventLineTime = [];
        // 数据
        this.eventLineData = [];
        let obj = {};
        this.$http({
          url: "api/topic/viewPoint/topicProgress",
          method: "get",
          params: {
            topicId: this.id,
          },
        }).then((res) => {
          if (res.data.data) {
            // 接口返回数据
            this.eventLineList = res.data.data;
          }
          for (let i = 0; i < this.eventLineList.length; i++) {
            // 图标上方8个图标
            if (
              this.eventLineNames.indexOf(this.eventLineList[i].showname) == -1
            ) {
              this.eventLineNames.push(this.eventLineList[i].showname);
              obj = {
                name: this.eventLineList[i].showname,
                type: "bar",
                symbol: "none",
                stack: "正",
                itemStyle: {
                  color:
                    this.eventLineList[i].showname == "电视"
                      ? "#1D50FF"
                      : this.eventLineList[i].showname == "纸媒"
                        ? "#819DFF"
                        : this.eventLineList[i].showname == "网站"
                          ? "#1393FF"
                          : this.eventLineList[i].showname == "微博"
                            ? "#6EBCFF"
                            : this.eventLineList[i].showname == "微信公众号"
                              ? "#00B5A2"
                              : this.eventLineList[i].showname == "移动客户端"
                                ? "#85D9D0"
                                : this.eventLineList[i].showname == "互动栏目"
                                  ? "#FFAE45"
                                  : this.eventLineList[i].showname == "视频"
                                    ? "#FFDCA1"
                                    : "",
                },
                barWidth: 6,
                data: [],
              };
              // 八个对象 柱子
              this.eventLineData.push(obj);
            }
            // x轴
            if (
              this.eventLineTime.indexOf(this.eventLineList[i].showTime) == -1
            ) {
              this.eventLineTime.push(this.eventLineList[i].showTime);
              this.eventLineTime.sort();
            }
          }
          this.eventLineData.forEach((it) => {
            this.eventLineTime.forEach((item) => {
              let off = false;
              this.eventLineList.forEach((i) => {
                if (item == i.showTime && it.name == i.showname) {
                  off = true;
                  if (it.data[it.data.length - 1]) {
                    it.data.push(it.data[it.data.length - 1] + i.todayComments);
                  } else {
                    it.data.push(i.todayComments);
                  }
                }
              });
              if (!off) {
                if (it.data[it.data.length - 1]) {
                  it.data.push(it.data[it.data.length - 1]);
                } else {
                  it.data.push(0);
                }
              }
            });
          });
          //  ezshidf
          let wholeObj = {
            name: "增量趋势",
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#FF509D",
            },
            yAxisIndex: 1,
            data: [],
          };
          this.eventLineTime.forEach((e) => {
            let arr = this.eventLineList.filter((n) => n.showTime == e); //找出相同时间的数组
            let num = 0;
            arr.forEach((i) => {
              num += i.todayComments; //累加相同时间的数据
            });
            wholeObj.data.push(num); //总体趋势data
          });
          this.eventLineData.push(wholeObj);
          resolve();
        });
      });
    },
    echarts1() {
      let divs = document.createElement("div");
      divs.setAttribute("id", "div1");
      divs.style.width = "385px";
      divs.style.height = "250px";
      let myChart = echarts.init(divs);
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        title: [
          {
            text: "各环节整体分布",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#B8BCC3",
              fontSize: 14,
              fontWeight: "normal",
            },
          },
        ],
        series: [
          {
            name: "全部",
            type: "pie",
            center: ["50%", "40%"],
            radius: 70,
            minAngle: 10,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
            label: {
              formatter: "{b}\n{@整体}",
            },
            data: this.inforListTotal,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.$store.state.dataListTopic.linkDistribution = picInfo;
      });
    },
    echartstwo() {
      let divs = document.createElement("div");
      divs.setAttribute("id", "div2");
      divs.style.width = "385px";
      divs.style.height = "250px";
      let myChart = echarts.init(divs);
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        title: [
          {
            text: "各环节今日新增分布",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#B8BCC3",
              fontSize: 14,
              fontWeight: "normal",
            },
          },
        ],
        series: [
          {
            name: "今天",
            type: "pie",
            center: ["50%", "40%"],
            radius: 70,
            minAngle: 10,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
            label: {
              formatter: "{b}\n{@整体}",
            },
            data: this.inforListToday,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.$store.state.dataListTopic.linkDistributionTwo = picInfo;
      });
    },
    echarts2() {
      let divs = document.createElement("div");
      divs.setAttribute("id", "div3");
      divs.style.width = "385px";
      divs.style.height = "250px";
      let myChart = echarts.init(divs);
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        title: [
          {
            text: "各环节整体分布",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#B8BCC3",
              fontSize: 14,
              fontWeight: "normal",
            },
          },
        ],
        label: {
          formatter: "{b}\n{@整体}",
        },
        series: [
          {
            name: "全部",
            type: "pie",
            center: ["50%", "40%"],
            radius: 70,
            minAngle: 10,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
            data: this.commentListTotal,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.$store.state.dataListTopic.reviewData = picInfo;
      });
    },
    echarts2two() {
      let divs = document.createElement("div");
      divs.setAttribute("id", "div4");
      divs.style.width = "385px";
      divs.style.height = "250px";
      let myChart = echarts.init(divs);
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        title: [
          {
            text: "各环节今日新增分布",
            left: "50%",
            top: "85%",
            textAlign: "center",
            textStyle: {
              color: "#B8BCC3",
              fontSize: 14,
              fontWeight: "normal",
            },
          },
        ],
        label: {
          formatter: "{b}\n{@整体}",
        },
        series: [
          {
            name: "今天",
            type: "pie",
            center: ["50%", "40%"],
            radius: 70,
            minAngle: 10,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
            data: this.commentListToday,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.$store.state.dataListTopic.reviewDataTwo = picInfo;
      });
    },
    // 事件进展曲线
    echarts3() {
      // eventLine
      let myChart = echarts.init(document.querySelector(".eventLine"));
      let option = {
        grid: {
          left: "6%",
          right: "6%",
          bottom: 50,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: [
          {
            right: "35%",
            align: "left",
            itemWidth: 7, // 设置宽度class
            itemHeight: 7,
            itemGap: 40, // 设置间距im
            icon: "circle",
            textStyle: {
              color: "#AAAAAA",
            },
            data: [
              {
                name: "电视",
              },
              {
                name: "纸媒",
              },
              {
                name: "网站",
              },
              {
                name: "微博",
              },
              {
                name: "微信公众号",
              },
              {
                name: "移动客户端",
              },
              {
                name: "互动栏目",
              },
              {
                name: "视频",
              },
            ],
          },
          {
            right: "28%",
            align: "left",
            itemWidth: 18, // 设置宽度class
            itemHeight: 10,
            textStyle: {
              color: "#AAAAAA",
            },
            data: ["增量趋势"],
          },
        ],
        dataZoom: [
          {
            type: "inside",
          },
          {
            startValue: this.eventLineTime[0],
          },
        ],
        xAxis: {
          type: "category",
          data: this.eventLineTime,
          borderColor: "#D8D8D8",
          axisLine: {
            lineStyle: {
              color: "#D8D8D8",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            name: "总量",
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#CCCCCC",
                fontSize: 12,
              },
            },
            boundaryGap: ["0%", "50%"],
            splitLine: {
              lineStyle: {
                color: "#E5E5E5",
                type: "dashed",
              },
            },
            minInterval: 1, //将刻度的最小间距设置为1
          },
          {
            name: "增量",
            axisLine: {
              lineStyle: {
                color: "#CCCCCC",
                fontSize: 12,
              },
            },
            alignTicks: true,
            type: "value",
            minInterval: 1, //将刻度的最小间距设置为1
          },
        ],
        // series: this.eventLineData   wholeObj
        series: this.eventLineData,
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.$store.state.dataListTopic.topicProgress = picInfo;
      });
    },
    // 评论曲线
    commentLine() {
      if (this.topicType != 1) {
        return false;
      }
      return new Promise((resolve) => {
        this.$http({
          url: "api/topic/viewPoint/commentCurve",
          method: "get",
          params: {
            topicId: this.id,
          },
        }).then((res) => {
          if (res.data.data) {
            this.tabledata = res.data.data;
            if (this.tabledata.length != 0) {
              this.$store.state.multipleSelection.forEach((item) => {
                if (!Array.isArray(item)) {
                  if (!item.mainkeyword) {
                    this.multipleSelection = [this.tabledata[0]];
                  } else {
                    this.multipleSelection =
                      this.$store.state.multipleSelection;
                  }
                }
              });
              this.handleSelectionChange(this.multipleSelection);
            }
          }
          resolve();
        });
      });
    },
    // 点击切换时的数据
    handleSelectionChange(val) {
      let key = [];
      let time = [];
      let obj = {};
      let arrData = [];
      let color = ["#009BF9", "#FFAE45"];
      this.multipleSelection = val;
      this.multipleSelection.forEach((item, index) => {
        if (item.mainkeyword) {
          obj = {
            name: item.mainkeyword,
            type: "line",
            stack: item.mainkeyword,
            color: color[index],
            smooth: true,
            symbolSize: 5,
            areaStyle: {
              normal: {
                //右，下，左，上
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#009BF9",
                    },
                    {
                      offset: 1,
                      color: "#ffffff",
                    },
                  ],
                  false
                ),
              },
            },
            data: [],
          };
          arrData.push(obj);
          key.push(item.mainkeyword);
          for (let key in item.map) {
            // item.map[key]对象的值
            time.push(key);
            obj.data.push([key, item.map[key]]);
          }
          obj.data.sort();
        }
      });
      time = time.sort();
      this.key = key;
      this.time = time;
      this.arrData = arrData;
    },
    echarts4(key, time, arrData) {
      let divs = document.createElement("div");
      divs.setAttribute("id", "div6");
      divs.style.width = "1233px";
      divs.style.height = "340px";
      let myChart = echarts.init(divs);
      myChart.clear();
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        dataZoom: [
          {
            startValue: time[0],
            left: "10%",
            right: "13%",
          },
          {
            type: "inside",
          },
        ],
        legend: {
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 20,
          x: "45%",
          y: "4%",
          data: key,
        },
        grid: {
          left: "5%",
          right: "7%",
          bottom: "15%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: time,
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1, //将刻度的最小间距设置为1
          },
        ],
        //数据
        series: arrData,
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.$store.state.dataListTopic.graph = picInfo;
      });
    },
    // 媒体热力图
    mediaHot(item) {
      return new Promise((resolve) => {
        this.$http({
          url: "api/topic/send/mediaHeat",
          method: "post",
          data: {
            topicId: this.id,
            mediaLink: item == "全部" ? "" : item,
          },
        }).then((res) => {
          if (res.data.data && res.data.data.length != 0) {
            res.data.data.forEach((item) => {
              item.name = item.mediaLink;
              item.value = item.provinceSum;
            });
            this.GetMapData = res.data.data;
            let MapDate = [];
            this.GetMapData.forEach((item) => {
              if (item.mediaLink != "其他" && item.mediaLink != "全国") {
                item.name = item.mediaLink;
                item.value = item.provinceSum;
                MapDate.push(item);
              }
            });
            MapDate.sort((a, b) => {
              return a.provinceSum - b.provinceSum;
            });
            this.GetMapData = MapDate;
            echarts.registerMap("China", map);
            let myChart = echarts.init(document.querySelector(".mapData"));
            let data = {
              region: [],
              value: [],
              average: [],
            };
            let sum = 0;
            this.GetMapData.forEach((x) => {
              data.region.push(x.name);
              data.value.push(x.value);
              sum += x.value;
            });
            data.region = data.region.reverse();
            data.value = data.value.reverse();
            data.value.forEach(() => {
              data.average.push(sum / data.value.length);
            });
            if (data.region.length >= 10) {
              data.region.splice(10);
            } else {
              while (data.region.length < 10) {
                data.region.push("");
              }
            }
            let option = {
              title: [
                {
                  show: true,
                  text: "TOP 10 排行榜",
                  right: 40,
                  textStyle: { color: "#666666", fontSize: 12 },
                },
              ],
              tooltip: {
                trigger: "item",
                backgroundColor: "rgba(255, 255, 255, 0.98)",
                borderWidth: 0,
                formatter: function (params) {
                  if (params.data == undefined) {
                    return "";
                  }
                  let str = `<div>
                  <p style="width:200px;display:flex;justify-content: space-between;">
                  <span style="font-size: 14px;font-weight: 550;color: #2E59EC;">${params.data.name}</span>
                  <span style="font-size: 14px;font-weight: 550;color: #2E59EC;">${params.data.value}</span>
                  </p></div>`;
                  return str;
                },
              },
              legend: { show: false },
              grid: {
                show: true,
                containLabel: false,
                right: 40,
                top: 50,
                bottom: 30,
                width: "20%",
              },
              visualMap: {
                type: "continuous",
                min: 0,
                max:
                  this.GetMapData[this.GetMapData.length - 1] &&
                    this.GetMapData[this.GetMapData.length - 1].value
                    ? this.GetMapData[this.GetMapData.length - 1].value
                    : 1,
                text: ["多", "少"],
                seriesIndex: [0, 2],
                dimension: 0,
                realtime: false,
                left: 50,
                bottom: 50,
                itemWidth: 18,
                itemHeight: 100,
                calculable: true,
                inRange: {
                  color: [
                    "#e6ecfc",
                    "#addbff",
                    "#5cb8ff",
                    "#6fa5ff",
                    "#3779ff",
                    "#3b64f1",
                  ],
                },
                outOfRange: {
                  color: ["#eeeeee"],
                },
              },
              toolbox: {
                show: false,
              },
              xAxis: [
                {
                  type: "value",
                  position: "top",
                  inside: false,
                  axisLabel: { show: false },
                  splitLine: { show: false },
                  margin: 10,
                },
              ],
              yAxis: [
                {
                  type: "category",
                  boundaryGap: true,
                  axisLine: { show: false },
                  axisLabel: { align: "right", margin: 10, showMaxLabel: true },
                  inverse: true,
                  data: data.region,
                },
              ],
              series: [
                {
                  name: "占比",
                  type: "map",
                  mapType: "China",
                  left: "100",
                  width: "60%",
                  mapValueCalculation: "sum",
                  zoom: 1,
                  top: 10,
                  selectedMode: false,
                  showLegendSymbol: false,
                  label: {
                    normal: { show: true, color: "#fff", fontSize: 9 },
                    emphasis: { show: true, color: "#fff" },
                  },
                  itemStyle: {
                    normal: { areaColor: "#e6ecfc", borderColor: "#fff" },
                    emphasis: { areaColor: "#3b64f1", borderWidth: 2 },
                  },
                  data: this.GetMapData,
                },
                {
                  name: "背景",
                  type: "bar",
                  roam: false,
                  visualMap: false,
                  itemStyle: { color: "#eeeeee", opacity: 0.5 },
                  label: { show: false },
                  emphasis: {
                    itemStyle: { color: "#eeeeee", opacity: 0.5 },
                    label: { show: false },
                  },
                  silent: true,
                  barWidth: 18,
                  barGap: "-100%",
                  data: data.average,
                  animation: false,
                },
                {
                  name: "占比",
                  type: "bar",
                  roam: false,
                  visualMap: false,
                  barWidth: 18,
                  label: {
                    normal: {
                      show: true,
                      fontSize: 12,
                      position: "insideLeft",
                    },
                    emphasis: { show: true },
                  },
                  tooltip: {
                    show: false,
                  },
                  data: data.value,
                },
              ],
            };
            myChart.setOption(option);
            let picInfo;
            let _this = this;
            setTimeout(() => {
              picInfo = myChart.getDataURL();
              _this.$store.state.dataListTopic.mediaAnalysis = picInfo;
            }, 3000);
          }
          resolve();
        });
      });
    },
    //信息地域图
    createdInfoMap() {
      return ascription({ topicId: this.id + "" }).then((res) => {
        let data = {
          region: [],
          value: [],
          average: [],
        };
        let mapData = [];
        let sum = 0;
        if (res.data.code == 200) {
          let value = res.data.data;
          let shi = ["北京市", "天津市", "上海市", "重庆市"];
          value.forEach((x) => {
            let j = false;
            let k = false;
            shi.forEach((y) => {
              if (y.indexOf(x.mediaLink) != -1) {
                j = true;
                k = y === x.mediaLink;
                x.mediaLink = k ? x.mediaLink : x.mediaLink + "市";
              }
            });
            if (j) {
              if (mapData[x.mediaLink]) {
                mapData[x.mediaLink].value += x.count;
              } else {
                mapData.push({
                  name: x.mediaLink,
                  value: x.count,
                });
              }
            } else if (x.mediaLink.indexOf("省") != -1) {
              let judge = true;
              mapData.forEach((y) => {
                if (x.mediaLink == y.name) {
                  judge = false;
                  y.value += x.count;
                }
              });
              if (judge) {
                mapData.push({
                  name: x.mediaLink,
                  value: x.count,
                });
              }
            } else {
              let judge = true;
              mapData.forEach((y) => {
                if (x.mediaLink + "省" == y.name) {
                  judge = false;
                  y.value += x.count;
                }
              });
              if (judge) {
                mapData.push({
                  name: x.mediaLink + "省",
                  value: x.count,
                });
              }
            }
          });
          mapData.sort(function (a, b) {
            return b.value - a.value;
          });
          value.forEach((x) => {
            if (
              x.province.indexOf("省") != -1 ||
              x.province.indexOf("市") != -1
            ) {
              data.region.push(x.province);
            } else if (x.mediaLink.indexOf(x.province) == -1) {
              data.region.push(x.province + "市");
            } else {
              data.region.push(x.province + "省");
            }
            data.value.push(x.count);
            sum += x.count;
          });
          data.value.forEach(() => {
            data.average.push(sum / data.value.length);
          });
          if (data.region.length >= 10) {
            data.region.splice(10);
          } else {
            while (data.region.length < 10) {
              data.region.push("");
            }
          }
        }
        mapData.forEach((x) => {
          if (x.name === "新疆省") {
            x.name = "新疆自治区";
          }
          if (x.name === "宁夏省") {
            x.name = "宁夏自治区";
          }
          if (x.name === "西藏省") {
            x.name = "西藏自治区";
          }
          if (x.name === "广西省") {
            x.name = "广西自治区";
          }
          if (x.name === "内蒙古省") {
            x.name = "内蒙古自治区";
          }
        });
        echarts.registerMap("China", map);
        let myChart = echarts.init(document.getElementById("infoMapData"));
        let option = {
          animation: false,
          title: [
            {
              show: true,
              text: "TOP 10 排行榜",
              right: "40",
              textStyle: { color: "#666666", fontSize: 12 },
            },
          ],
          tooltip: {
            trigger: "item",
            backgroundColor: "rgba(255, 255, 255, 0.98)",
            borderWidth: 0,
            formatter: function (params) {
              if (params.data == undefined) {
                return "";
              }
              let str = `<div>
              <p style="width:200px;display:flex;justify-content: space-between;">
              <span style="font-size: 14px;font-weight: 550;color: #2E59EC;">${params.data.name}</span>
              <span style="font-size: 14px;font-weight: 550;color: #2E59EC;">${params.data.value}</span>
              </p></div>`;
              return str;
            },
          },
          legend: { show: false },
          grid: {
            show: true,
            containLabel: false,
            right: 40,
            top: 50,
            bottom: 30,
            width: "20%",
          },
          visualMap: {
            type: "continuous",
            min: 0,
            max: mapData.length > 0 ? mapData[0].value : 1,
            text: ["多", "少"],
            seriesIndex: [0, 2],
            dimension: 0,
            realtime: false,
            left: 50,
            bottom: 50,
            itemWidth: 18,
            itemHeight: 100,
            calculable: true,
            inRange: {
              color: [
                "#e6ecfc",
                "#addbff",
                "#5cb8ff",
                "#6fa5ff",
                "#3779ff",
                "#3b64f1",
              ],
            },
            outOfRange: {
              color: ["#eeeeee"],
            },
          },
          toolbox: {
            show: false,
          },
          xAxis: [
            {
              type: "value",
              position: "top",
              inside: false,
              axisLabel: { show: false },
              splitLine: { show: false },
              margin: 10,
            },
          ],
          yAxis: [
            {
              type: "category",
              boundaryGap: true,
              axisLine: { show: false },
              axisLabel: { align: "right", margin: 10, showMaxLabel: true },
              inverse: true,
              data: data.region,
            },
          ],
          series: [
            {
              name: "占比",
              type: "map",
              mapType: "China",
              left: "100",
              width: "60%",
              mapValueCalculation: "sum",
              zoom: 1,
              top: 10,
              selectedMode: false,
              showLegendSymbol: false,
              label: {
                normal: { show: true, color: "#fff", fontSize: 9 },
                emphasis: { show: true, color: "#fff" },
              },
              itemStyle: {
                normal: { areaColor: "#e6ecfc", borderColor: "#fff" },
                emphasis: { areaColor: "#3b64f1", borderWidth: 2 },
              },
              data: mapData,
            },
            {
              name: "背景",
              type: "bar",
              roam: false,
              visualMap: false,
              itemStyle: { color: "#eeeeee", opacity: 0.5 },
              label: { show: false },
              emphasis: {
                itemStyle: { color: "#eeeeee", opacity: 0.5 },
                label: { show: false },
              },
              silent: true,
              barWidth: 18,
              barGap: "-100%",
              data: data.average,
              animation: false,
            },
            {
              name: "占比",
              type: "bar",
              roam: false,
              visualMap: false,
              barWidth: 18,
              label: {
                normal: { show: true, fontSize: 12, position: "insideLeft" },
                emphasis: { show: true },
              },
              tooltip: {
                show: false,
              },
              data: data.value,
            },
          ],
        };
        myChart.setOption(option);
        this.mapUrl = myChart.getDataURL();
      });
    },
    //专项报告生成
    async SpecialReport() {
      if (this.GraphText) {
        return false;
      }
      this.GraphText = true;
      let res = await specialData({ topicId: this.id });
      //生成曲线图
      if (res.data.code == 200) {
        this.generateGraph(res.data.data);
      } else {
        this.$message({
          message: res.data.msg,
          type: "warning",
        });
        this.GraphText = false;
      }
    },
    async generateGraph(res) {
      const promises = this.renderCharts(res);
      const results = await Promise.all(promises);
      this.GraphLine = results[0];
      this.GraphPie = results[1];
      this.wordCloud = results[2];
      this.spcialReport();
    },
    renderCharts(res) {
      const chartOptions = this.getChartOptions();
      const divLine = document.createElement('div');
      divLine.style.width = '1626px';
      divLine.style.height = '800px';
      const divPie = document.createElement('div');
      divPie.style.width = '1167px';
      divPie.style.height = '690px';
      const divWordCloud = document.createElement('div');
      divWordCloud.style.width = '1167px';
      divWordCloud.style.height = '690px';

      const lineChart = this.renderLineChart(divLine, res, chartOptions.line);
      const pieChart = this.renderPieChart(divPie, res, chartOptions.pie);
      const wordCloud = this.renderWordCloud(divWordCloud, res, chartOptions.wordCloud);
      return [
        this.handleChartComplete(lineChart),
        this.handleChartComplete(pieChart),
        this.handleChartComplete(wordCloud)
      ];
    },
    getChartOptions() {
      return {
        line: {
          grid: {
            bottom: 180
          },
          // legend: {
          //   show: true,
          //   bottom: 0,
          //   textStyle: {
          //     fontSize: 30
          //   }
          // },
          xAxis: {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: '#dfdfdf'
              }
            },
            axisTick: {
              lineStyle: {
                color: '#dfdfdf'
              }
            },
            axisLabel: {
              rotate: 90,
              color: '#616262',
              fontSize: 20,
              overflow: 'breakAll',
              fontWeight: 'bold'
            },
            // splitLine: {
            //   show: true,
            //   lineStyle: {
            //     color: '#e0e6f1',
            //     width: 1,
            //     type: 'dashed'
            //   }
            // }
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#dfdfdf'
              }
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: '#dfdfdf'
              }
            },
            axisLabel: {
              color: '#616262',
              fontSize: 20,
              overflow: 'breakAll',
              fontWeight: 'bold'
            },
            splitLine: {
              show: false,
            }
          },
          series: [
            {
              type: 'line',
              name: "全部",
              smooth: true,
              symbol: 'rect',
              lineStyle: {
                color: '#3371ff',
                width: 3
              },
              label: {
                show: true,
              },
              // areaStyle: {
              //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //     {
              //       offset: 0,
              //       color: '#dfd4f9'
              //     },
              //     {
              //       offset: 1,
              //       color: '#ffffff'
              //     }
              //   ])
              // },
              data: [],
              labelLayout: {  // ECharts 5+ 的新特性
                hideOverlap: true,  // 'shiftX' 或 'none' 等值，用于处理重叠的标签
              },
              markLine: {
                symbol: ['none', 'none'],
                label: {
                  show: false
                },
                data: [],
                lineStyle: {
                  color: '#e0e6f1',
                  width: 1
                }
              }
            },
            // {
            //   type: 'line',
            //   name: "电视",
            //   smooth: true,
            //   symbol: 'rect',
            //   lineStyle: {
            //     color: '#06c0d5',
            //     width: 3
            //   },
            //   label: {
            //     show: true,
            //   },
            //   areaStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       {
            //         offset: 0,
            //         color: '#c0f5fb'
            //       },
            //       {
            //         offset: 1,
            //         color: '#ffffff'
            //       }
            //     ])
            //   },
            //   data: []
            // },
            // {
            //   type: 'line',
            //   name: "纸媒",
            //   smooth: true,
            //   symbol: 'rect',
            //   lineStyle: {
            //     color: '#558bf7',
            //     width: 3
            //   },
            //   label: {
            //     show: true,
            //   },
            //   areaStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       {
            //         offset: 0,
            //         color: '#bbc8ee'
            //       },
            //       {
            //         offset: 1,
            //         color: '#ffffff'
            //       }
            //     ])
            //   },
            //   data: []
            // },
            // {
            //   type: 'line',
            //   name: "网站",
            //   smooth: true,
            //   symbol: 'rect',
            //   lineStyle: {
            //     color: '#ffc800',
            //     width: 3
            //   },
            //   label: {
            //     show: true,
            //   },
            //   areaStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       {
            //         offset: 0,
            //         color: '#f7e6aa'
            //       },
            //       {
            //         offset: 1,
            //         color: '#ffffff'
            //       }
            //     ])
            //   },
            //   data: []
            // },
            // {
            //   type: 'line',
            //   name: "微博",
            //   smooth: true,
            //   symbol: 'rect',
            //   lineStyle: {
            //     color: '#9adb00',
            //     width: 3
            //   },
            //   label: {
            //     show: true,
            //   },
            //   areaStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       {
            //         offset: 0,
            //         color: '#dbf797'
            //       },
            //       {
            //         offset: 1,
            //         color: '#ffffff'
            //       }
            //     ])
            //   },
            //   data: []
            // },
            // {
            //   type: 'line',
            //   name: "微信公众号",
            //   smooth: true,
            //   symbol: 'rect',
            //   lineStyle: {
            //     color: '#32c267',
            //     width: 3
            //   },
            //   label: {
            //     show: true,
            //   },
            //   areaStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       {
            //         offset: 0,
            //         color: '#8ccda4'
            //       },
            //       {
            //         offset: 1,
            //         color: '#ffffff'
            //       }
            //     ])
            //   },
            //   data: []
            // },
            // {
            //   type: 'line',
            //   name: "移动客户端",
            //   smooth: true,
            //   symbol: 'rect',
            //   lineStyle: {
            //     color: '#471ab2',
            //     width: 3
            //   },
            //   label: {
            //     show: true,
            //   },
            //   areaStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       {
            //         offset: 0,
            //         color: '#c4aef7'
            //       },
            //       {
            //         offset: 1,
            //         color: '#ffffff'
            //       }
            //     ])
            //   },
            //   data: []
            // },
            // {
            //   type: 'line',
            //   name: "互动栏目",
            //   smooth: true,
            //   symbol: 'rect',
            //   lineStyle: {
            //     color: '#047380',
            //     width: 3
            //   },
            //   label: {
            //     show: true,
            //   },
            //   areaStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       {
            //         offset: 0,
            //         color: '#51787c'
            //       },
            //       {
            //         offset: 1,
            //         color: '#ffffff'
            //       }
            //     ])
            //   },
            //   data: []
            // },
            // {
            //   type: 'line',
            //   name: "视频",
            //   smooth: true,
            //   symbol: 'rect',
            //   lineStyle: {
            //     color: '#0945be',
            //     width: 3
            //   },
            //   label: {
            //     show: true,
            //   },
            //   areaStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       {
            //         offset: 0,
            //         color: '#8596b9'
            //       },
            //       {
            //         offset: 1,
            //         color: '#ffffff'
            //       }
            //     ])
            //   },
            //   data: []
            // }
          ]
        },
        pie: {
          // legend: {
          //   show: true,
          //   right: 0,
          //   orient: "vertical",
          //   textStyle: {
          //     fontSize: 30
          //   }
          // },
          series: [
            {
              type: 'pie',
              radius: ['40%', '70%'],
              minAngle: 10,
              label: {
                position: 'outside',
                fontWeight: 'bold',
                fontSize: 24,
                formatter: '{b},{c}条,{d}%',
                color: "inherit"
              },
              labelLine: {
                show: false
              }
            }
          ]
        },
        wordCloud: {
          grid: {
            top: "5%",
            bottom: "5%",
            left: "5%",
            ight: "5%"
          },
          series: [
            {
              type: "wordCloud",
              //用来调整词之间的距离
              gridSize: 20,
              sizeRange: [35, 95],
              rotationRange: [0, 0],
              //随机生成字体颜色
              textStyle: {
                color: function () {
                  return (
                    "rgb(" +
                    Math.round(Math.random() * 255) +
                    ", " +
                    Math.round(Math.random() * 255) +
                    ", " +
                    Math.round(Math.random() * 255) +
                    ")"
                  );
                },
              },
              left: "center",
              top: "center",
              right: null,
              bottom: null,
              width: "800%",
              height: "800%"
            },
          ],
        }
      };
    },
    renderLineChart(div, res, option) {
      console.log(res, '>>>>');

      const xAxis = [];
      if (res.mediaLinkStatList) {
        res.mediaLinkStatList.forEach((entry, index) => {
          xAxis.push(entry.x);
          option.series[0].data.push(entry.y.total);
          option.series[0].markLine.data.push({
            xAxis: index
          });
          // option.series[1].data.push(entry.y.tv);
          // option.series[2].data.push(entry.y.paperMedia);
          // option.series[3].data.push(entry.y.website);
          // option.series[4].data.push(entry.y.weibo);
          // option.series[5].data.push(entry.y.wechatPublicAccount);
          // option.series[6].data.push(entry.y.mobileClient);
          // option.series[7].data.push(entry.y.interactiveColumn);
          // option.series[8].data.push(entry.y.video);
        });
      }
      option.xAxis.data = xAxis;
      option.series.forEach((item) => {
        item.label = {
          show: true,
          fontWeight: 'bold',
          fontSize: 24,
          position: 'top',
          formatter: function (params) {
            return `${params.value}`;
          }
        }
      });
      const myChart = echarts.init(div);
      myChart.setOption(option);
      return myChart;
    },
    renderPieChart(div, res, option) {
      const pieData = [];
      if (res.infolist) {
        res.infolist.forEach((x) => {
          pieData.push({ value: x.totalComments, name: x.showname });
        });
      }
      option.series[0].data = pieData;
      const myChart = echarts.init(div);
      myChart.setOption(option);
      return myChart;
    },
    renderWordCloud(div, res, option) {
      const WordCloud = [];
      if (res.commentCurveList) {
        res.commentCurveList.forEach((item) => {
          WordCloud.push({
            name: item.mainkeyword,
            value: item.amount,
          });
        });
      }
      option.series[0].data = WordCloud;
      const myChart = echarts.init(div);
      myChart.setOption(option);
      return myChart;
    },
    handleChartComplete(chart) {
      return new Promise((resolve) => {
        chart.on('finished', () => {
          const picInfo = chart.getDataURL({
            type: 'png',
            pixelRatio: 1.5,
            backgroundColor: '#fff'
          });
          resolve(picInfo);
          chart.dispose();
        });
      });
    },
    //专项报告导出
    spcialReport() {
      let url = "api/topicnew/word/v2/spcialReport";
      let data = {
        topicId: this.id,
        eventProgressionImg: this.GraphLine,
        mediaDataImg: this.GraphPie,
        wordCloud: this.wordCloud
      };
      let timer = new Date();
      let hour =
        timer.getHours() < 9 ? "0" + timer.getHours() : timer.getHours();
      let minutes =
        timer.getMinutes() < 9 ? "0" + timer.getMinutes() : timer.getMinutes();
      let textName = `${this.topicName}报告-${this.$store.state.user.name}-${hour}：${minutes}.docx`;
      this.$parent.downloadFun2({ url, data, textName });
      this.GraphText = false;
    },
    // 观点分析
    viewPoint() {
      return new Promise((resolve) => {
        this.$http({
          url: "api/topic/send/viewPoint",
          method: "get",
          params: {
            topicId: this.id,
          },
        }).then((res) => {
          if (res != undefined) {
            if (res.data.data && res.data.data.length != 0) {
              this.dataList = res.data.data;
              if (Object.values(this.$store.state.radio).length > 0) {
                this.radio = this.$store.state.radio;
              } else {
                this.radio = this.dataList[0];
              }
              this.changeRadio();
            }
          }
          resolve();
        });
      });
    },
    changeRadio() {
      this.dataEchart = [];
      if (this.radio.list.length != 0) {
        this.radio.list.forEach((ite) => {
          let obj = {
            category: ite.levels,
            name: ite.source + " - " + ite.levels,
            itemStyle: {
              shadowColor:
                ite.levels == "全国"
                  ? "rgba(255, 85, 117, 0.21)"
                  : ite.levels == "省级"
                    ? "rgba(255, 146, 83, 0.17)"
                    : ite.levels == "市级"
                      ? "rgba(25, 201, 183, 0.21)"
                      : "rgba(92, 146, 255, 0.28)",
              shadowBlur: 10,
            },
            symbolSize:
              ite.levels == "全国"
                ? 96
                : ite.levels == "省级"
                  ? 76
                  : ite.levels == "市级"
                    ? 56
                    : 36,
          };
          this.dataEchart.push(obj);
        });
      }
    },
    echarts6(dataEchart) {
      let divs = document.createElement("div");
      divs.setAttribute("id", "div8");
      divs.style.width = "976px";
      divs.style.height = "320px";
      let myChart = echarts.init(divs);
      var option = {
        color: ["#FF5575", "#FF965A", "#19C9B7", "#3A7FFF", "#3A7FFF"],
        series: [
          {
            type: "graph",
            layout: "force",
            force: {
              repulsion: 300,
            },
            data: dataEchart,
            categories: [
              {
                name: "全国",
              },
              {
                name: "省级",
              },
              {
                name: "市级",
              },
              {
                name: "其他",
              },
              {
                name: "区县级",
              },
            ],
            label: {
              show: true,
              color: "black",
              fontSize: "12px",
            },
            labelLayout: {
              hideOverlap: true,
            },
            scaleLimit: {
              min: 0.4,
              max: 2,
            },
          },
        ],
      };
      setTimeout(() => {
        myChart.setOption(option);
      }, 500);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      let picInfo;
      let _this = this;
      myChart.on("finished", function () {
        picInfo = myChart.getDataURL();
        _this.$store.state.dataListTopic.mediaDistribution = picInfo;
      });
    },
    // 评论曲线
    // 控制样式的
    tableRowClassName({ row }) {
      let self = this;
      for (let i = 0; i < self.multipleSelection.length; i++) {
        if (row == self.multipleSelection[i]) {
          return "warning-row";
        }
      }
    },
    topicSampleList() {
      if (this.topicType != 1) {
        return false;
      }
      return new Promise((resolve) => {
        this.$http({
          url: "api/topic/topicSample/list",
          method: "post",
          data: {
            topicId: this.id,
          },
        }).then((res) => {
          if (res.data.rows) {
            this.options = res.data.rows;
            if (Object.values(this.$store.state.sampling).length > 0) {
              this.sampling = this.$store.state.sampling;
            } else {
              this.sampling = res.data.rows[0];
            }
            if (res.data.rows.length != 0) {
              this.optionsChange(this.sampling);
            }
          }
          resolve();
        });
      });
    },
    // 选择抽样的时候改变事件 切换观点列表
    optionsChange(val) {
      return new Promise((resolve) => {
        this.samplingName = val.createTime + " " + val.remark;
        this.samplingCount = val.sampleName;
        this.samplingiD = val.sampleId;
        this.$http({
          url: "api/topic/topicViewpoint/list",
          method: "post",
          data: {
            sampleId: val.sampleId,
            topicId: this.id,
            totalNum: val.sampleName,
          },
        }).then((res) => {
          if (res.data.rows) {
            this.viewPointList = res.data.rows;
            if (!this.$store.state.dataListTopic.netizensOne) {
              this.$nextTick(() => {
                html2canvas(document.querySelector("#showings4")).then(
                  (resolve) => {
                    let imgUrl = resolve.toDataURL("image/png");
                    this.$store.state.dataListTopic.netizensOne = imgUrl;
                  }
                );
              });
            }
            if (this.viewPointList.length != 0) {
              if (Object.values(this.$store.state.radio2).length > 0) {
                this.radio2 = this.$store.state.radio2;
              } else {
                this.radio2 = res.data.rows[0];
              }
              this.radioChange(this.radio2);
            }
          }
          resolve();
        });
      });
    },
    // 选择观点的时候 切换右边评论列表
    radioChange(val) {
      return new Promise((resolve) => {
        this.loadingContent = true;
        this.pageSize = val.totalNum;
        let data = {
          topicId: this.id,
          sampleId: this.samplingiD,
          viewpointIds: val.viewpointId,
          isSetSample: 1,
        };
        let urls = `?pageNum=1&pageSize=${this.pageSize}`;
        this.$http({
          url: "api/topic/topicComment/list" + urls,
          method: "post",
          data: data,
        }).then((res) => {
          if (res.data.rows) {
            this.commitList = res.data.rows;
            this.commitList.forEach((item) => {
              this.viewPointList.forEach((it) => {
                if (item.viewpointId == it.viewpointId) {
                  item.viewName = it.viewName;
                }
              });
            });
            if (this.commitList.length != 0) {
              this.$nextTick(() => {
                html2canvas(document.querySelector("#showing5")).then(
                  (resolve) => {
                    let imgUrl = resolve.toDataURL("image/png");
                    this.$store.state.dataListTopic.sampleOne = imgUrl;
                  }
                );
              });
            }
          }
          this.loadingContent = false;
          resolve();
        });
      });
    },
    jump(item) {
      sessionStorage.setItem("eventName", item.value);
      sessionStorage.setItem("inforName", "全部信息");
      this.name = item.value;
    },
    // 深度更新
    async updata() {
      const res = await topicList({
        organId: this.$store.state.user.organizationId,
        isDeepUpdate: 0, //0未关闭 1关闭
      });
      if (
        res.data.rows.length > this.$store.state.user.topicLimit - 1 &&
        this.isDeepUpdate == 1
      ) {
        this.$message.warning("当前活动话题已达上限！请您关闭话题后再操作。");
      } else {
        let res = await editTopic({
          topicId: this.topicId,
          isUpdate: 1,
          organId: this.$store.state.user.organizationId,
          updateTypeId: this.isPause ? 1 : 0,
          userEdit: 0,
          isPause: 0,
          isDeepUpdate: 0, //0未关闭 1关闭
          id: this.editTopic,
        });
        if (res.data.code == 200) {
          this.$message.success("深度更新成功!");
        } else {
          this.$message.error("深度更新失败!");
        }
        this.getInfo();
      }
    },
    // 关闭
    async closeing() {
      const res = await editTopic({
        organId: this.$store.state.user.organizationId,
        topicId: this.topicId,
        isUpdate: 0, //未深度更新
        userEdit: 0,
        isDeepUpdate: 1, //关闭
        isPause: 0, //未更新
        id: this.id,
      });
      if (res.data.code == 200) {
        this.$message({
          message: "关闭成功！",
          type: "success",
        });
      } else {
        this.$message.error("关闭失败！");
      }
      this.getInfo();
    },
    async getInfo() {
      const res = await getInfo({
        topicId: this.id,
      });
      if (res.data.data) {
        this.topicName = res.data.data.topicName;
        this.topid = res.data.data.id;
        sessionStorage.setItem("topicName", this.topicName);
        this.isDel = res.data.data.isDel;
        this.isPause = res.data.data.isPause;
        this.isDeepUpdate = res.data.data.isDeepUpdate;
        this.isUpdate = res.data.data.isUpdate;
        this.publicType = res.data.data.publicTopicType;
        this.topicData = res.data.data;
      }
    },
    deletes() {
      let item = {
        message: "话题",
        id: this.id,
      };
      this.$refs.openDialog.openDialog(item);
    },
    async deleteFn(item) {
      const res = await deletes({
        id: item.id,
      });
      if (res.data.code == 200) {
        this.$message({
          message: "删除成功!",
          type: "success",
        });
        if (this.type == "index") {
          this.$router.push({
            path: "/topics/index",
          });
        } else if (this.type == "history") {
          this.$router.push({
            path: "/topics/index/topicList",
          });
        }
      } else {
        this.$message.error("删除失败！请稍后再试！");
      }
    },
    handelLishi() {
      sessionStorage.setItem("eventName", "generalization");
      if (this.type == "history") {
        this.$router.push({
          path: "/topics/index/topicList",
        });
      } else {
        this.$router.push({
          path: "/topics/index",
        });
      }
    },
    fenxiJump() {
      window.open(
        `http://dp.new.sjyq.yuqing.pro/FenXi/?Token=${sessionStorage.getItem(
          "token"
        )}&TopicID=${this.id}&organId=${this.$store.state.user.organizationId}`
      );
    },
    zaixianJump() {
      window.open(
        `http://dp.new.sjyq.yuqing.pro/ZaiXian/?Token=${sessionStorage.getItem(
          "token"
        )}&TopicID=${this.id}&organId=${this.$store.state.user.organizationId}`
      );
    },
  },
  mounted() { },
};
</script>

<style lang="scss" scoped>
.box {
  .breadcrumb {
    margin-top: 77px;
    margin-left: 24px;
  }

  .box-top {
    width: 100%;
    min-height: 56px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;

    .topicName {
      max-width: 1360px;
    }

    .box-top-left {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      color: #333333;

      .box-top-left-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border: 1px solid #cccccc;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 16px;
      }
    }

    .box-top-right {
      display: flex;

      div {
        padding: 6px 24px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 16px;
      }

      .box-top-right-btn1 {
        background: #fff6f7;
        color: #ea3342;
      }

      .box-top-right-btn2 {
        background: #fdf6ef;
        color: #ff8000;
      }

      .box-top-right-btn3 {
        background: #e9f4ff;
        color: #2e59ec;
      }

      .box-top-right-btn4 {
        background: #e1fef4;
        color: #02bc7c;
      }
    }
  }

  .box-content {
    padding: 0 24px 24px 24px;

    .box-content-top {
      width: 100%;
      height: 54px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 48px;
      padding-right: 16px;
      font-size: 16px;
      color: #333333;
      margin-bottom: 16px;

      .playback {
        display: flex;
        height: 32px;
        align-items: center;
        color: #ffffff;

        img {
          width: 14px;
          height: 14px;
          margin-right: 3px;
          position: relative;
          top: 3px;
        }
      }

      .sentimentPy {
        background: linear-gradient(304deg, #00bbdd 0%, #21dbb8 100%);
      }

      .sentimentFx {
        background: linear-gradient(114deg, #16aef3 0%, #027ffe 100%);
      }

      .Selectli {
        line-height: 54px;
        margin-right: 80px;
        color: #2e59ec;
        border-bottom: 2px solid #2e59ec;
        cursor: pointer;
        font-weight: bold;
      }

      .NoSelect {
        line-height: 54px;
        margin-right: 80px;
        cursor: pointer;
      }
    }
  }

  ::v-deep .el-breadcrumb__separator {
    margin: 0 2px;
  }

  .disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
  }
}

.delete-wrap {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  height: 700px;
  padding-top: 208px;
}

.delete-div {
  text-align: center;
}

.delete-div>p {
  font-size: 14px;
  color: #999999;
}

.delete-img {
  position: relative;
  width: 368px;
  margin: auto auto;
}

.delete-x {
  font-size: 70px;
  color: #ffb2b2;
  position: absolute;
  bottom: 10px;
  right: 120px;
  z-index: 22;
}

.box1-div2-center-left-table {
  position: absolute;
  top: -999px;
  font-size: 14px;
  margin-top: 33px;
  width: 769px;
  background: #ffffff;
  border: 1px solid #e4e6ec;
  display: flex;
  flex-wrap: wrap;

  li {
    display: flex;
    flex: 1;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #f5f6fa;

    .box1-div2-center-left-table-left {
      width: 120px;
      text-align: center;
      border-right: solid 2px #fff;
      border-left: solid 2px #fff;
    }

    .box1-div2-center-left-table-right {
      width: 260px;
      text-align: center;
    }
  }

  // li:nth-child(3),
  // li:nth-child(4),
  // li:nth-child(7),
  // li:nth-child(8) {
  //   background: #ffffff;
  // }

  // li:nth-child(1),
  // li:nth-child(3),
  // li:nth-child(5),
  // li:nth-child(7) {
  //   .box1-div2-center-left-table-left {
  //     border-left: none;
  //   }
  // }
}

.box1-div4-center-left {
  position: absolute;
  top: -999px;
  width: 492px;
  background: #ffffff;
  padding: 40px;

  ::v-deep .el-table .warning-row {
    color: #2e59ec !important;
  }

  ::v-deep .has-gutter .el-checkbox {
    display: none !important;
  }
}

.box2-div2-center-left {
  position: absolute;
  top: -999px;
  background: #ffffff;
  width: 495px;

  ul {
    background: #ffffff;
    width: 100%;
    border: 1px solid #e4e6ec;

    li {
      display: flex;
      height: 40px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;

      .li-left {
        width: 161px;
        background: #f5f6fa;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-right: 1px solid #e4e6ec;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }

      .li-center {
        width: 200px;
        background: #f5f6fa;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-right: 1px solid #e4e6ec;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }

      .li-centerNum {
        width: 80px;
        font-size: 14px;
        color: #333333;
        font-weight: bold;
        background: #f5f6fa;
      }

      .redioText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .li-right {
        width: 430px;
        background: #f5f6fa;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 32px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }

      .li-left1 {
        width: 161px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-right: 1px solid #e4e6ec;
        padding-right: 8px;
        font-size: 14px;
        color: #333333;
      }

      .li-left1 .el-radio {
        width: 100%;
      }

      .li-left1 .el-radio .el-radio__label {
        width: 100%;
      }

      .li-center1 {
        width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-right: 1px solid #e4e6ec;
        font-size: 14px;
        color: #333333;
      }

      .li-center2 {
        width: 80px;
        font-size: 14px;
        color: #333333;
      }

      .li-right1 {
        width: 430px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 32px;
        font-size: 14px;
        color: #333333;
      }
    }

    .itemLi {
      color: #2e59ec;
    }
  }
}

.mediaView-div1 {
  width: 276px;
  height: 40px;
  border-right: 1px solid rgb(228, 230, 236);
  background: #f5f6fa;
}

.mediaView-div2 {
  width: 120px;
  height: 40px;
  border-right: 1px solid rgb(228, 230, 236);
  background: #f5f6fa;
  text-align: center;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}

.mediaView-div3 {
  height: 40px;
  background: #f5f6fa;
  text-align: center;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  width: 97px;
}

.mediaView-div4 {
  width: 276px;
  border-right: 1px solid #e4e6ec;
}

.mediaView-div5 {
  width: 120px;
  border-right: 1px solid #e4e6ec;
  text-align: center;
}

.mediaView-div6 {
  width: 97px;
  text-align: center;
}

.li-left1 {
  width: 161px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-right: 1px solid #e4e6ec;
  padding-right: 8px;
  font-size: 14px;
  color: #333333;
}

.li-left1 .el-radio {
  width: 100%;
}

.li-left1 .el-radio .el-radio__label {
  width: 100%;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.box2-div2 {
  position: absolute;
  top: -999px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;

  .box2-div2-top {
    display: flex;
    align-items: center;
    padding: 24px 16px;
  }

  .img-box {
    display: flex;
    align-items: center;
  }

  .box2-div2-center {
    display: flex;

    .box2-div2-center-left {
      background: #ffffff;
      padding-left: 44px;
      padding-bottom: 25px;
      width: 868px;

      ul {
        background: #ffffff;
        width: 100%;
        border: 1px solid #e4e6ec;

        li {
          display: flex;
          height: 40px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;

          .li-left {
            width: 161px;
            background: #f5f6fa;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-right: 1px solid #e4e6ec;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
          }

          .li-center {
            width: 120px;
            background: #f5f6fa;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-right: 1px solid #e4e6ec;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
          }

          .li-centerNum {
            width: 80px;
            font-size: 14px;
            color: #333333;
            font-weight: bold;
            background: #f5f6fa;
          }

          .redioText {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .li-right {
            width: 430px;
            background: #f5f6fa;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 32px;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
          }

          .li-left1 {
            width: 161px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-right: 1px solid #e4e6ec;
            padding-right: 8px;
            font-size: 14px;
            color: #333333;
          }

          .li-left1 .el-radio {
            width: 100%;
          }

          .li-left1 .el-radio .el-radio__label {
            width: 100%;
          }

          .li-center1 {
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-right: 1px solid #e4e6ec;
            font-size: 14px;
            color: #333333;
          }

          .li-center2 {
            width: 80px;
            font-size: 14px;
            color: #333333;
          }

          .li-right1 {
            width: 430px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 32px;
            font-size: 14px;
            color: #333333;
          }
        }

        .itemLi {
          color: #2e59ec;
        }
      }
    }

    #echarts2 {
      height: 230px;
      width: 100%;
    }
  }
}

.viewPoint-right-content {
  width: 1040px;
  max-height: 475px;
  overflow-y: auto;
  border: 1px solid #e4e6ec;
  padding: 20px;
  margin-top: 16px;
  padding-bottom: 0;
}

.viewPoint-div {
  width: 100%;
  border-bottom: 1px solid #e4e6ec;
  margin-bottom: 20px;
}

.viewPoint-tag,
.viewPoint-top-text,
.viewPoint-top-font {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.viewPoint-tag {
  min-width: 72px;
  height: 24px;
  background: #36cbcb;
  text-align: center;
  line-height: 24px;
  color: #fff;
  font-size: 14px;
}

.viewPoint-top-text {
  color: #333;
  font-size: 14px;
}

.viewPoint-top-font {
  color: #333;
  font-size: 14px;
}

.viewPoint-right-center {
  margin: 16px 0;
}

.viewPoint-right-content-text {
  font-size: 16px;
  color: #666666;
  margin-right: 8px;
}

.viewPoint-right-content-font {
  font-size: 16px;
  color: #333;
}

.viewPoint-right-content-font:hover {
  color: #2e59ec;
  cursor: pointer;
}

.viewPoint-right-bottom {
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.viewPoint-right-bottom-text {
  color: #b8bcc3;
  font-size: 14px;
  float: left;
}

.viewPoint-right-bottom-font {
  color: #b8bcc3;
  font-size: 14px;
  float: right;
}

.viewPoint-right {
  margin-left: 30px;
  margin-bottom: 20px;
}

.viewPoint-right-font {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.viewPoint-left-text {
  height: 19px;
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 16px;
}

.eventLine {
  position: absolute;
  top: -999px;
  width: 1540px;
  height: 280px;
}

.mapData {
  position: absolute;
  top: -1000px;
  width: 1626px;
  height: 800px;
}

#infoMapData {
  position: absolute;
  top: -1000px;
  width: 1626px;
  height: 800px;
}
</style>
